import { Pathnames } from './config';

export default {
  '/': '/',
  '/game': {
    en: '/game',
    tr: '/oyun',
  },
  '/game/lobby': {
    en: '/game/lobby',
    tr: '/oyun/lobi',
  },
  '/blog': {
    en: '/blog',
    tr: '/blog',
  },
  '/shop': {
    en: '/shop',
    tr: '/dukkan',
  },
  '/future': {
    en: '/future',
    tr: '/gelecek',
  },
  '/contact': {
    en: '/contact',
    tr: '/iletisim',
  },
  '/auth': {
    en: '/auth',
    tr: '/yetkilendirme',
  },
  '/auth/login': {
    en: '/auth/login',
    tr: '/yetkilendirme/giris-yap',
  },
  '/auth/register': {
    en: '/auth/register',
    tr: '/yetkilendirme/kayit-ol',
  },
  '/auth/forgot-password': {
    en: '/auth/forgot-password',
    tr: '/yetkilendirme/sifremi-unuttum',
  },
  '/auth/verify-email': {
    en: '/auth/verify-email',
    tr: '/yetkilendirme/email-dogrula',
  },
  '/auth/logout': {
    en: '/auth/logout',
    tr: '/yetkilendirme/cikis-yap',
  },
  '/profile': {
    en: '/profile',
    tr: '/profil',
  },
  '/profile/plan': {
    en: '/profile/plan',
    tr: '/profil/plan',
  },
  '/profile/notification': {
    en: '/profile/notification',
    tr: '/profil/bildirim',
  },
  '/profile/message': {
    en: '/profile/message',
    tr: '/profil/mesaj',
  },
  '/profile/setting': {
    en: '/profile/setting',
    tr: '/profil/ayar',
  },
  '/profile/friend': {
    en: '/profile/friend',
    tr: '/profil/arkadas',
  },
} satisfies Pathnames;
